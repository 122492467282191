<template>
    <div class="modal" @click="closeModal">
        <div class="modal-content" @click.stop>
            <div class="image-container">
                <span @click="closeModal" class="close">&times;</span>
                <img :src="src" alt="" :width="imageWidth" :height="imageHeight" @load="handleImageLoad"/>
                <div class="zoom-buttons">
                    <div class="zoom-container">
                        <div v-show="isVisibleZoomIn" @click="zoomIn" class="zoom-button zoom-in">
                            <span>+</span>
                        </div>
                    </div>
                    <div class="zoom-container">
                        <div v-show="isVisibleZoomOut" @click="zoomOut" class="zoom-button zoom-out">
                            <span>-</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            required: true,
        },
        maxWidth: {
            type: Number,
            required: true,
        },
        maxHeight: {
            type: Number,
            required: true,
        },
        minWidth: {
            type: Number,
            default: 500,
        }
    },
    data() {
        return {
            imageWidth: 0,
            imageHeight: 0,
        };
    },
    methods: {
        zoomIn() {
            if(this.imageWidth < this.maxWidth && this.imageHeight < this.maxHeight) {
                this.imageWidth += 50;
                this.imageHeight += 50;
            }
        },
        zoomOut() {
            if (this.imageWidth > this.minWidth) {
                this.imageWidth -= 50;
                this.imageHeight -= 50;
            }
        },
        closeModal() {
            this.$emit('close');
        },
        handleImageLoad(event) {
            const img = event.target;

            const aspectRatio = img.naturalWidth / img.naturalHeight;
            const windowAspectRatio = this.maxWidth / this.maxHeight;

            const scalingFactor = 1;

            if (aspectRatio > windowAspectRatio) {
                this.imageWidth = this.maxWidth * scalingFactor;
                this.imageHeight = (this.maxWidth * scalingFactor) / aspectRatio;
            } else {
                this.imageHeight = this.maxHeight * scalingFactor;
                this.imageWidth = (this.maxHeight * scalingFactor) * aspectRatio;
            }
        }
    },
    computed: {
        isVisibleZoomIn() {
            return this.imageWidth < this.maxWidth && this.imageHeight < this.maxHeight;
        },
        isVisibleZoomOut() {
            return this.imageWidth > this.minWidth;
        },
    },
};
</script>

<style>
.modal img {
    max-width: unset;
    height: unset;
}

.modal {
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image-container {
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.zoom-buttons {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.zoom-button {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    cursor: pointer;
}

.zoom-container {
    width: 40px;
    height: 40px;
    margin: 5px;
}

.zoom-button:focus {
    outline: none;
}
</style>
