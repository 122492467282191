<template>
    <ul>
        <li
            v-for="section in sectionsWithIndices"
            :key="section.id"
            :style="{ 'margin-left': level ? '10px' : '0' }"
            :class="{ 'parent-section': !level }"
        >
            <div class="button-container" @click="toggleSection(section)">
                <div
                    class="section-button button"
                    :class="{ 'selected': selectedSection && selectedSection.id === section.id}"
                >
                    {{ section.index }} {{ section.title }}
                </div>
                <arrow-black class="section-icon" :class="{ rotate: openSections.includes(section.id) }"/>
            </div>
            <ul v-if="openSections.includes(section.id) || isSearching">
                <li
                    v-for="(article, articleIndex) in section.articles"
                    :key="article.id"
                    @click="articleSelected(article)"
                    class="article-button button"
                    :class="{'selected': selectedArticle && selectedArticle.id === article.id }"
                    :style="{ 'margin-left': '10px' }"
                >
                    {{ section.index && section.index.endsWith('.') ? section.index.slice(0, -1) : section.index }}.{{ articleIndex + 1 }} {{ article.title }}
                </li>
                <section-tree
                    v-if="section.children.length"
                    :sections="section.children"
                    :selectedArticle="selectedArticle"
                    :selected-section="selectedSection"
                    :level="section.index"
                    :is-searching="isSearching"
                    :count-parent-articles="section.articles.length"
                    :open-sections="openSections"
                    @article-selected="$emit('article-selected', $event)"
                    @section-selected="$emit('section-selected', $event)"
                ></section-tree>
            </ul>
        </li>
    </ul>
</template>

<script>
import ArrowBlack from '../../../images/arrow-black.svg';
export default {
    name: 'section-tree',
    components: {
        ArrowBlack,
    },
    props: ['sections', 'selectedArticle', 'selectedSection' , 'level', 'isSearching', 'countParentArticles', 'openSections'],
    computed: {
        sectionsWithIndices() {
            let index = this.countParentArticles;
            return this.sections.map((section) => {
                const sectionHasChildren = section.children.length > 0 || section.articles.length > 0;
                let currentIndex = this.level ? `${this.level}${index + 1}` : `${index + 1}`;
                if (sectionHasChildren) {
                    currentIndex += ".";
                }
                index++;
                return {
                    ...section,
                    index: currentIndex
                };
            });
        }
    },
    methods: {
        toggleSection(section) {
            const sectionId = section.id;
            const index = this.openSections.indexOf(sectionId);
            if (index === -1) {
                this.openSections.push(sectionId);
            } else {
                this.openSections.splice(index, 1);
            }
            this.$emit('section-selected', section);
        },
        articleSelected(article) {
            this.$emit('article-selected', article);
        }
    },
};
</script>

<style scoped>
.button:hover {
    color: #F9AF1E;
}

.button-container {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.button {
    font-size: 16px;
    font-weight: 600;
    display: block;
    color: black;
    cursor: pointer;
}

.button.selected {
    color: #F9AF1E;
}

.section-button {
    padding-bottom: 20px;
}

.article-button {
    padding-bottom: 12px;
}

.parent-section {
    border-bottom: 1px solid #DBDADE;
    margin-bottom: 20px;
}

.section-icon {
    margin-top: 7px;
    transition: transform 0.3s ease;
}

.section-icon.rotate {
    transform: rotate(180deg);
}

</style>
