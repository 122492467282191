import Vue from 'vue';
import ArticleApp from './components/articles/ArticleApp.vue';
import ArticleEducationApp from './components/articles/education/ArticleEducationApp.vue';
import { OverlayScrollbars, ClickScrollPlugin } from 'overlayscrollbars';
import 'overlayscrollbars/overlayscrollbars.css'

OverlayScrollbars.plugin(ClickScrollPlugin);
window.OverlayScrollbars = OverlayScrollbars;
window.Vue = Vue;
Vue.component('article-app', ArticleApp);
Vue.component('article-education-app',  ArticleEducationApp);

if (document.querySelector('#app')) {
    new Vue({
        el: '#app',
    });
}
