<template>
    <div class="page-wrapper__content active">
        <image-modal
            v-if="showModal"
            :src="selectedImage"
            :max-width="windowWidth"
            :max-height="windowHeight"
            @close="showModal = false"
        />
        <div
            :class="{'mobile' : isMobile, 'hide' : selectedElement}"
            class="page-content"
            v-cloak
        >
            <div class="container section-container">
                <div class="section">
                    <div
                        class="page-blog__grid-item_blog"
                        @mouseover="disableBodyScroll"
                        @mouseleave="enableBodyScroll"
                    >
                        <h1 class="page-content__title title">База знаний</h1>
                        <input
                            v-model="search"
                            class="page-content__search search-input"
                            type="search"
                            placeholder="Поиск"
                        >
                        <div
                            v-if="!sections.length"
                            class="page-block__grid-item"
                        >
                            <div class="profile-content">
                                <div class="profile-content__block">
                                    <p class="profile-content__text">Здесь пока нет учебных материалов.</p>
                                    <p class="profile-content__text">Мы скоро это исправим.</p>
                                    <p class="profile-content__text">А пока можно посмотреть курсы в <a :href='allCoursesUrl' class='profile-content__text-link'>каталоге</a>.</p>
                                </div>
                            </div>
                        </div>

                        <div
                            v-else
                            class="scroll-menu menu-container"
                            ref="scrollMenu"
                        >
                            <section-tree
                                :sections="filteredSections"
                                :selected-article="selectedArticle"
                                :selected-section="selectedSection"
                                :is-searching="isSearching"
                                :count-parent-articles="0"
                                :open-sections="openSections"
                                @article-selected="articleSelected"
                                @section-selected="sectionSelected"
                            ></section-tree>
                        </div>
                    </div>
                    <div
                        class="scroll page-blog__grid-item_article" ref="scrollArticle"
                        @mouseover="disableBodyScroll"
                        @mouseleave="enableBodyScroll"
                    >
                        <div
                            v-if="isFilterMenuOpen && shouldOpenFilterMenu"
                        >
                            <h3 class="filter-menu__title">Найдено статей: {{ filteredArticlesList.length }}</h3>
                            <a
                                v-for="article in filteredArticlesList"
                                :key="article.id"
                                :href="`/articles/${article.slug || article.id}/`"
                                @click.prevent="articleSelected(article)"
                                class="filter-menu__article"
                            >
                              <h4 class="filter-menu__article-title">{{ article.title }}</h4>
                              <p
                                  v-for="description in article.filteredDescriptions"
                                  v-html="description"
                                  class="filter-menu__article-description"
                              ></p>
                            </a>
                        </div>
                        <div v-else>
                            <div
                                v-if="selectedElement && !isMobile"
                                class="full-article mce-content-body"
                            >
                                <h2>{{ selectedElement.title }}</h2>
                                <div v-if="selectedElement.created_at" class="article-date">
                                    Обновлено: {{ selectedElement.updated_at ? new Date(selectedElement.updated_at).toLocaleString('ru-RU') : new Date(selectedElement.created_at).toLocaleString('ru-RU') }}
                                </div>
                                <div
                                    v-html="selectedElement.description"
                                    class="article-description"
                                    ref="description"
                                ></div>
                            </div>
                            <div
                                v-else
                                class="empty-article"
                            >
                                <not-chosen-image class="empty-article__img"/>
                                <p class="empty-article__text empty-bold">Пока ни одной статьи не выбрано</p>
                                <p class="empty-article__text empty-regular">В панели слева выберите тему</p>
                            </div>
                        </div>

                        <div class="scroll-to-top_container">
                            <div
                                v-show="showScrollToTopButton"
                                class="scroll-to-top article-btn"
                                @click="scrollToTop"
                            >
                                <arrow-image/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="{'open-article' : selectedElement}"
            class="article-mobile"
            v-cloak
        >
            <div
                v-if="selectedElement && isMobile"
                class="full-article"
            >
                <div
                    class="arrow-container article-btn"
                    @click="back"
                >
                    <full-arrow-image/>
                    <div>К списку тем</div>
                </div>
                <span
                    class="mobile-title"
                    v-text="selectedElement.title"
                ></span>
                <div class="article-date">
                    Обновлено: {{ selectedElement.updated_at ? new Date(selectedElement.updated_at).toLocaleString('ru-RU') : new Date(selectedElement.created_at).toLocaleString('ru-RU') }}
                </div>
                <div
                    v-html="selectedElement.description"
                    class="article-description mce-content-body"
                    ref="description"
                ></div>
                <div
                    v-show="showScrollToTopButtonMobile"
                    class="scroll-to-top article-btn scroll-to-top__mobile"
                    @click="scrollToTopMobile"
                >
                    <arrow-image/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SectionTree from './SectionTree.vue';
import ImageModal from './ImageModal.vue';
import NotChosenImage from '../../../images/not-chosen.svg';
import ArrowImage from '../../../images/arrow-accent.svg';
import FullArrowImage from '../../../images/full-arrow.svg';

export default {
    name: 'ArticleApp',
    components: {
        SectionTree,
        ImageModal,
        NotChosenImage,
        ArrowImage,
        FullArrowImage,
    },
    props: [
        'initialSections',
        'openArticleId',
        'openSectionId',
        'initialTags',
        'authOver',
        'allCoursesUrl',
    ],
    data() {
        return {
            sections: this.initialSections,
            tags: this.initialTags,
            selectedArticle: null,
            selectedSection: null,
            search: '',
            selectedTags: [],
            showScrollToTopButton: false,
            showScrollToTopButtonMobile : false,
            isFilterMenuOpen: false,
            scrollMenuInstance: null,
            scrollArticleInstance: null,
            scrollTagsInstance: null,
            openSections: [],
            filteredArticlesList: [],
            windowWidth: 0,
            windowHeight: 0,
            showModal: false,
            selectedImage: '',
        };
    },
    mounted() {
        window.addEventListener('popstate', this.handlePopState);
        const findArticleById = (sections, articleId, path = []) => {
            for (const section of sections) {
                for (const article of section.articles || []) {
                    if (article.id === articleId || article.slug === articleId) {
                        return { article, path: [...path, section.id] };
                    }
                }
                if (section.children) {
                    const found = findArticleById(section.children, articleId, [...path, section.id]);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        };

        const findSectionById = (sections, sectionId, path = []) => {
            for (const section of sections) {
                if (section.id === sectionId || section.slug === sectionId) {
                    return { section, path: [...path, section.id] };
                }
                if (section.children) {
                    const found = findSectionById(section.children, sectionId, [...path, section.id]);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        };

        const scrollMenuElement = this.$refs.scrollMenu;
        const scrollArticleElement = this.$refs.scrollArticle;
        const scrollTagsElement = this.$refs.scrollTags;

        this.scrollMenuInstance = OverlayScrollbars(scrollMenuElement,
            {
                scrollbars: {
                    theme: 'os-theme-dark',
                    autoHide: 'leave',
                    autoHideDelay: 100,
                    clickScroll: true,
                }
            }
        );

        this.scrollArticleInstance = OverlayScrollbars(scrollArticleElement,
            {
                scrollbars: {
                    theme: 'os-theme-dark',
                    autoHide: 'leave',
                    autoHideDelay: 100,
                    clickScroll: true,
                }
            },
            {
                scroll: (instance, event) => {
                    this.handleScroll(event);
                },
            }
        );

        this.scrollTagsInstance = (this.isWidget() && this.tags.length > 1) ? OverlayScrollbars(scrollTagsElement,
            {
                scrollbars: {
                    theme: 'os-theme-invisible',
                    autoHide: 'leave',
                    autoHideDelay: 100,
                    clickScroll: true,
                },
            },
            {
                initialized(osInstance) {
                    const viewport = osInstance.elements().viewport;
                    viewport.style.display = "flex";
                    viewport.style.flexWrap = "nowrap";

                    viewport.addEventListener('wheel', function(e) {
                        e.preventDefault();
                        viewport.scrollLeft += e.deltaY;
                    });
                }
            }
        ) : null;

        window.addEventListener('scroll', this.handleMobileScroll);

        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        if(this.openSectionId) {
            const result = findSectionById(this.sections, this.openSectionId);
            this.selectedSection = (result && !this.isMobile) ? result.section : null;
            this.openSections = result ? result.path : [];
        } else {
            const result = findArticleById(this.sections, this.openArticleId);
            this.selectedArticle = result ? result.article : null;
            this.openSections = result ? result.path : [];
        }

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('scroll', this.handleMobileScroll);
        window.removeEventListener('popstate', this.handlePopState);
    },
    computed: {
        filteredSections() {
            this.filteredArticlesList = [];
            if (!this.search && this.selectedTags.length === 0) return this.sections;
            return this.filterSections(this.sections, this.search, this.selectedTags);
        },
        isSearching() {
            return this.search.length > 0 || this.selectedTags.length > 0
        },
        isMobile() {
            return this.windowWidth <= 900;
        },
        selectedElement() {
            return this.selectedArticle || this.selectedSection;
        },
        shouldOpenFilterMenu() {
            return this.filteredArticlesList.length > 0;
        },
    },
    watch: {
        isMobile(newIsMobile, oldIsMobile) {
            if (newIsMobile) {
                this.selectedSection = null;
            }
        },
        selectedElement(newSelectedElement, oldSelectedElement) {
            if (newSelectedElement) {
                this.addImageClickHandler();
            }
        },
        showModal(newShowModal, oldShowModal) {
            if(newShowModal) {
                document.body.classList.add('no-scroll')
            } else {
                document.body.classList.remove('no-scroll');
            }
        },
        search() {
            this.selectedArticle = null;
            this.isFilterMenuOpen = true;
        },
    },
    methods: {
        addImageClickHandler() {
            this.$nextTick(() => {
                if (!this.$refs.description) return;
                const images = this.$refs.description.querySelectorAll('img');
                images.forEach((img) => {
                    img.addEventListener('click', () => {
                        this.selectedImage = img.src;
                        this.showModal = true;
                    });
                });
            });
        },
        isWidget() {
            return this.authOver === 'widget';
        },
        updateUrlWithParam(entity, name) {
            const baseUrl = window.location.origin + "/articles";
            const queryParams = new URLSearchParams();
            if (entity.slug) {
                if(name === 'section') queryParams.set("section", entity.slug);
                else if(name === 'article') queryParams.set("article", entity.slug);
            } else if (entity.id) {
                if(name === 'section') queryParams.set("section", entity.id);
                else if (name === 'article') queryParams.set("article", entity.id);
            }

            const newUrl = `${baseUrl}/?${queryParams.toString()}`;
            if (window.location.href !== newUrl) {
                window.history.pushState({}, '', newUrl);
            }
        },
        articleSelected(article) {
            this.selectedSection = null;
            this.selectedArticle = article;
            this.isFilterMenuOpen = false;
            this.updateUrlWithParam(article, 'article');
        },
        sectionSelected(section) {
            this.updateUrlWithParam(section, 'section');
            if(!this.isMobile) {
                this.isFilterMenuOpen = false;
                this.selectedArticle = null;
                this.selectedSection = section;
            }
        },
        updateSelectedTags(tag, event) {
            if (event.target.checked) {
                this.selectedTags.push(tag.id);
            } else {
                const index = this.selectedTags.indexOf(tag.id);
                if (index > -1) {
                    this.selectedTags.splice(index, 1);
                }
            }
        },
        filterArticles(articles, search, selectedTags) {
            return articles.filter(article => {
                const searchRegex = new RegExp(search, 'ig');
                const isMatchingArticle = (article === this.selectedArticle) ||
                    ((!selectedTags.length || article.tags.some(tag => selectedTags.includes(tag.id))) &&
                        (this.removeHTMLTags(article.title).toLowerCase().includes(search.toLowerCase()) ||
                            this.removeHTMLTags(article.description).toLowerCase().includes(search.toLowerCase())));

                if (isMatchingArticle) {
                    let descriptions = this.extractRelevantParagraphs(article.description, searchRegex);
                    if (descriptions.length > 0) {
                        const highlightedDescriptions = descriptions.map(description =>
                            this.highlightSearchTerm(description, searchRegex)
                        );

                        const filteredArticle = {
                            ...article,
                            filteredDescriptions: highlightedDescriptions
                        };

                        this.filteredArticlesList.push(filteredArticle);
                    }
                }

                return isMatchingArticle;
            });
        },
        extractRelevantParagraphs(text, searchRegex) {
            const paragraphs = text.split(/<\/?p>/);
            return paragraphs.map(paragraph => this.removeHTMLTags(paragraph))
                .filter(paragraph => searchRegex.test(paragraph));
        },
        highlightSearchTerm(text, searchRegex) {
            return text.replace(searchRegex, '<span class="highlight">$&</span>');
        },
        filterSections(sections, search, selectedTags) {
            return sections.reduce((filtered, section) => {
                const matchingSection = section.title.toLowerCase().includes(search.toLowerCase());
                const filteredArticles = this.filterArticles(section.articles, search, selectedTags);
                const filteredChildren = section.children.length ? this.filterSections(section.children, search, selectedTags) : [];

                if (filteredArticles.length || filteredChildren.length) {
                    filtered.push({
                        ...section,
                        articles: filteredArticles,
                        children: filteredChildren
                    });
                }

                return filtered;
            }, []);
        },
        removeHTMLTags(str) {
            return str.replace(/<[^>]*>?/gm, '');
        },
        disableBodyScroll() {
            if (!this.isMobile && !this.showModal) document.body.classList.add('no-scroll')
        },
        enableBodyScroll() {
            if (!this.isMobile && !this.showModal) document.body.classList.remove('no-scroll');
        },
        handleMobileScroll(e) {
            if (this.isMobile) {
                this.showScrollToTopButtonMobile = e.target.scrollingElement.scrollTop > 50;
            }
        },
        handleScroll(e) {
            this.showScrollToTopButton = e.target.scrollTop > 50;
        },
        easeInOutCubic(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t * t + b;
            t -= 2;
            return c / 2 * (t * t * t + 2) + b;
        },
        animateScroll(element, startPosition, duration) {
            const startTime = performance.now();
            const animate = (timestamp) => {
                const elapsedTime = timestamp - startTime;
                element.scrollTop = this.easeInOutCubic(elapsedTime, startPosition, -startPosition, duration);
                if (elapsedTime < duration) {
                    requestAnimationFrame(animate);
                }
            };
            requestAnimationFrame(animate);
        },
        scrollToTopMobile() {
            console.log('scrollToTopMobile');
            const element = document.documentElement || document.body;
            const startPosition = element.scrollTop;
            this.animateScroll(element, startPosition, 500);
        },
        scrollToTop() {
            const element = this.$refs.scrollArticle.querySelector(
                "[data-overlayscrollbars-viewport]"
            );
            const startPosition = element.scrollTop;
            this.animateScroll(element, startPosition, 500);
        },
        back() {
            this.selectedArticle = null;
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        handlePopState() {
            const parts = window.location.pathname.split('/');
            const slugOrId = parts[parts.length - 2];
            const article = this.findArticleBySlugOrId(slugOrId);
            if (article) {
                this.articleSelected(article);
            }
        },
        findArticleBySlugOrId(slugOrId, sections = this.sections) {
            for (let section of sections) {
                for (let article of section.articles) {
                    if (article.slug === slugOrId || article.id.toString() === slugOrId) {
                        return article;
                    }
                }

                if (section.children && section.children.length) {
                    const foundArticle = this.findArticleBySlugOrId(slugOrId, section.children);
                    if (foundArticle) {
                        return foundArticle;
                    }
                }
            }

            return null;
        },
    },
}
</script>

<style scoped>
.article-description >>> ul {
    list-style: unset;
}

.article-description >>> ol {
    list-style: auto;
}

.page-wrapper__content {
    width: 100%;
    padding: 36px 0;
}

.container {
    max-width: 1610px;
}

.arrow-container {
    display: flex;
    padding: 8px 20px;
    background-color: rgba(252, 239, 231, 1);
    width: 160px;
    height: 40px;
    color: rgba(238, 129, 79, 1);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 4px;
}

.arrow-container div {
    margin-left: 5px;
}

.article-btn {
    transition: background-color 0.5s ease 0s;
}

.article-btn:hover {
    color: #fff;
    background-color: #FFB930;
}

.article-btn:active {
    color: #fff;
    transform: scale(0.95);
}

.arrow-container img {
    width: 20px;
    margin-right: 8px;
}

.page-content .container {
    padding: 0 25px;
}

.title {
    align-self: center;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 24px;
}

.menu-container {
    padding: 48px 20px 0 0;
}

.parent-section > div > div {
    font-size: 17px;
    font-weight: 600;
}

.section {
    display: grid;
    margin-top: 0 !important;
    grid-template-columns: 25% 72%;
    gap: 3%;
}

.page-blog__grid-item_article {
    padding: 1rem;
    border-radius: 12px;
    background-color: #FFF;
}

.empty-article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: calc(70vh / 2);
}

.full-article {
    overflow-x: hidden;
    overflow-y: hidden;
}

.empty-article__text {
    text-align: center;
}

.empty-bold {
    margin-top: 28px;
    font-size: 17px;
    font-weight: 600;
}

.empty-regular {
    margin-top: 8px;
    font-size: 14px;
    color: rgba(2, 2, 3, 0.60);
}

.empty-article__img {
    width: 60px;
    align-self: center;
}

.scroll {
    height: calc(100vh - 150px);
}

.scroll-menu {
    height: calc(100vh - 280px);
}

.scroll-to-top {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #FFC759;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -8px;
}

.scroll-to-top_container {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: end;
}

.scroll-to-top img {
    width: 15px;
}

@-moz-document url-prefix() {
    .article-description table {
        border-collapse: unset !important;
    }
}

.article-mobile {
    display: none;
}

.article-date {
    padding-bottom: 10px;
}

.filter-menu__article {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}

.filter-menu__article-description {
    margin-top: 16px;
}

.filter-menu__article-description >>> .highlight {
    background-color: yellow;
}

@media screen and (max-width: 900px) {

    .container {
        width: 100vw;
    }

    .page-content {
        width: 100%;
    }

    .search-input {
        margin: 24px 0;
        height: 48px;
    }

    .title {
        font-size: 20px;
    }

    .article-mobile {
        display: block;
    }

    .article-mobile .article-description {
        padding: 16px 24px;
        border-radius: 12px;
        margin: 16px 0;
        background-color: #FFF;
        min-height: 55vh;
        overflow-x: auto;
    }

    .article-description table {
        width: 100% !important;
    }

    .section {
        grid-template-columns: 100%;
    }

    .open-article {
        width: 100vw;
        opacity: 1;
        visibility: visible;
        padding: 0 24px;
    }

    .page-blog__grid-item_article {
        display: none;
    }

    .page-wrapper__content {
        display: flex;
    }

    .mobile.hide {
        width: 0;
        opacity: 0;
        visibility: hidden;
        display: none;
    }

    .scroll-to-top__mobile {
        margin-right: unset;
        margin: 0 auto;
    }

    .scroll {
        height: unset;
    }

    .scroll-menu {
        height: unset;
    }

    .mobile-title {
        display: block;
        font-size: 20px;
        font-weight: 700;
        margin: 24px 0;
    }
}
</style>
