<template>
    <div class="page-wrapper__content" id="catalog">
        <div class="page-content">
            <div class="container">
                <div class="page-content__header">
                    <div class="page-content__header-block">
                        <h1 class="page-content__title">База знаний</h1>
                    </div>
                </div>
                <div class="page-content__wrapper" v-cloak>
                    <div class="tabs page-tabs vue_tabs">
                        <div class="filter">
                            <div class="filter-wrapper">
                                <div class="filter-wrapper__search">
                                    <input class="filter-wrapper__search-input" type="search" placeholder="Поиск" v-model="search">
                                </div>
                                <div class="filter-wrapper__category">
                                    <ul class="filter-category__list">

                                        <template v-if="tags.length > 0">
                                            <li class="filter-category__list-item" :class="{active: current_filter === 1}" data-name="theme" @click.prevent="toggle_filter(1)">
                                                <a href="#" class="filter-category__list-link">Теги</a>
                                            </li>
                                            <li class="filter-category__list-item filter-category__list-item_mobile filter-choise__block filter-block vue_filter_block" :class="{active: current_filter === 1}" data-name="tag">
                                                <div class="filter-choises">
                                                    <div v-for="tag in tags" class="filter-choises__block">
                                                        <div class="filter-checkbox">
                                                            <label class="filter-checkbox__label">
                                                                <input class="filter-checkbox__input" type="checkbox" :value="tag.id" v-model="choiceTags"><span class="filter-checkbox__checkmark">{{ tag.title }}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="filter-choises__block" v-if="choiceTags.length > 0">
                                                        <a class="filter-choises__btn clear_choises_btn" href="#" @click.prevent="clear('tags')">
                                                            <span class="filter-choises__btn-icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 18L6 6" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 6L6 18" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span><span class="filter-choises__btn-text">Снять выбор</span></a></div>
                                                </div>
                                            </li>
                                        </template>

                                        <template v-if="subjects.length > 0">
                                            <li class="filter-category__list-item" :class="{active: current_filter === 2}" @click.prevent="toggle_filter(2)">
                                                <a href="#" class="filter-category__list-link">Выбрать тему</a>
                                            </li>
                                            <li class="filter-category__list-item filter-category__list-item_mobile filter-choise__block filter-block vue_filter_block" :class="{active: current_filter === 2}" data-name="subject">
                                                <div class="filter-choises">
                                                    <div v-for="subject in subjects" class="filter-choises__block">
                                                        <div class="filter-checkbox">
                                                            <label class="filter-checkbox__label">
                                                                <input class="filter-checkbox__input" type="checkbox" :value="subject.id" v-model="choiceSubjects">
                                                                <span class="filter-checkbox__checkmark">{{ subject.title }}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="filter-choises__block" v-if="choiceSubjects.length > 0"><a class="filter-choises__btn clear_choises_btn" href="#" @click.prevent="clear('subjects')"><span class="filter-choises__btn-icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 18L6 6" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 6L6 18" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span><span class="filter-choises__btn-text">Снять выбор</span></a></div>
                                                </div>
                                            </li>
                                        </template>

                                        <template v-if="difficulties.length > 0">
                                            <li class="filter-category__list-item" :class="{active: current_filter === 3}" @click.prevent="toggle_filter(3)">
                                                <a href="#" class="filter-category__list-link">Выбрать сложность</a>
                                            </li>
                                            <li class="filter-category__list-item filter-category__list-item_mobile filter-choise__block filter-block vue_filter_block" :class="{active: current_filter === 3}" data-name="difficulty">
                                                <div class="filter-choises">
                                                    <div v-for="difficulty in difficulties" class="filter-choises__block">
                                                        <div class="filter-checkbox">
                                                            <label class="filter-checkbox__label">
                                                                <input class="filter-checkbox__input" type="checkbox" :value="difficulty.id" v-model="choiceDifficulties">
                                                                <span class="filter-checkbox__checkmark">{{ difficulty.title }}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="filter-choises__block" v-if="choiceDifficulties.length > 0"><a class="filter-choises__btn clear_choises_btn" href="#" @click.prevent="clear('difficulties')"><span class="filter-choises__btn-icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 18L6 6" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 6L6 18" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span><span class="filter-choises__btn-text">Снять выбор</span></a></div>
                                                </div>
                                            </li>
                                        </template>

                                        <template v-if="formats.length > 0">
                                            <li class="filter-category__list-item" :class="{active: current_filter === 4}" @click.prevent="toggle_filter(4)">
                                                <a href="#" class="filter-category__list-link">Выбрать формат</a>
                                            </li>
                                            <li class="filter-category__list-item filter-category__list-item_mobile filter-choise__block filter-block vue_filter_block" :class="{active: current_filter === 4}" data-name="format">
                                                <div class="filter-choises">
                                                    <div v-for="format in formats" class="filter-choises__block">
                                                        <div class="filter-checkbox">
                                                            <label class="filter-checkbox__label">
                                                                <input class="filter-checkbox__input" type="checkbox" :value="format.id" v-model="choiceFormats">
                                                                <span class="filter-checkbox__checkmark">{{ format.title }}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="filter-choises__block" v-if="choiceFormats.length > 0"><a class="filter-choises__btn clear_choises_btn" href="#" @click.prevent="clear('formats')"><span class="filter-choises__btn-icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 18L6 6" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 6L6 18" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span><span class="filter-choises__btn-text">Снять выбор</span></a></div>
                                                </div>
                                            </li>
                                        </template>

                                    </ul>
                                </div>
                            </div>
                            <div class="filter-choise filter-choise_desktop">

                                <div  v-if="tags.length > 0" class="filter-choise__block filter-block vue_filter_block" :class="{active: current_filter === 1 }" data-name="tag">
                                    <div class="filter-choises">
                                        <div v-for="tag in tags" class="filter-choises__block">
                                            <div class="filter-checkbox">
                                                <label class="filter-checkbox__label">
                                                    <input class="filter-checkbox__input" type="checkbox" :value="tag.id" v-model="choiceTags">
                                                    <span class="filter-checkbox__checkmark">{{ tag.title }}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="filter-choises__block" v-if="choiceTags.length > 0">
                                            <a class="filter-choises__btn clear_choises_btn" href="#" @click.prevent="clear('tags')">
                                                <span class="filter-choises__btn-icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 18L6 6" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 6L6 18" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
                                                <span class="filter-choises__btn-text">Снять выбор</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="subjects.length > 0" class="filter-choise__block filter-block vue_filter_block" :class="{active: current_filter === 2 }" data-name="subject">
                                    <div class="filter-choises">
                                        <div v-for="subject in subjects" class="filter-choises__block">
                                            <div class="filter-checkbox">
                                                <label class="filter-checkbox__label">
                                                    <input class="filter-checkbox__input" type="checkbox" :value="subject.id" v-model="choiceSubjects">
                                                    <span class="filter-checkbox__checkmark">{{ subject.title }}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="filter-choises__block" v-if="choiceSubjects.length > 0">
                                            <a class="filter-choises__btn clear_choises_btn" href="#" @click.prevent="clear('subjects')">
                                                <span class="filter-choises__btn-icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 18L6 6" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 6L6 18" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
                                                <span class="filter-choises__btn-text">Снять выбор</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="difficulties.length > 0" class="filter-choise__block filter-block vue_filter_block" :class="{active: current_filter === 3 }" data-name="difficulty">
                                    <div class="filter-choises">
                                        <div v-for="difficulty in difficulties" class="filter-choises__block">
                                            <div class="filter-checkbox">
                                                <label class="filter-checkbox__label">
                                                    <input class="filter-checkbox__input" type="checkbox" :value="difficulty.id" v-model="choiceDifficulties">
                                                    <span class="filter-checkbox__checkmark">{{ difficulty.title }}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="filter-choises__block" v-if="choiceDifficulties.length > 0">
                                            <a class="filter-choises__btn clear_choises_btn" href="#" @click.prevent="clear('difficulties')">
                                                <span class="filter-choises__btn-icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 18L6 6" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 6L6 18" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
                                                <span class="filter-choises__btn-text">Снять выбор</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="formats.length > 0" class="filter-choise__block filter-block vue_filter_block" :class="{active: current_filter === 4 }" data-name="format">
                                    <div class="filter-choises">
                                        <div v-for="format in formats" class="filter-choises__block">
                                            <div class="filter-checkbox">
                                                <label class="filter-checkbox__label">
                                                    <input class="filter-checkbox__input" type="checkbox" :value="format.id" v-model="choiceFormats">
                                                    <span class="filter-checkbox__checkmark">{{ format.title }}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="filter-choises__block" v-if="choiceFormats.length > 0">
                                            <a class="filter-choises__btn clear_choises_btn" href="#" @click.prevent="clear('formats')">
                                                <span class="filter-choises__btn-icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 18L6 6" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 6L6 18" stroke="#FFC759" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
                                                <span class="filter-choises__btn-text">Снять выбор</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="filteredSections.length > 0" class="tabs-content page-tabs__content">
                            <div v-for="section in filteredSections" :key="section.id" class="section">
                                <div class="section__header">
                                    <div class="section__header-block">
                                        <h1 class="page-content__title">{{ section.title }}</h1>
                                    </div>
                                    <div class="section__header-block all-sections">
                                        <a v-if="!isSection" :href="generateUrl(sectionsUrl, section.id)" class="all-url">К разделу</a>
                                        <a v-else :href="sectionsUrl">Все разделы</a>
                                    </div>
                                </div>
                                <div
                                    class="article-cards"
                                    :class="{'article-cards__dont-section': !isSection, 'article-cards_section': isSection}"
                                >
                                    <a v-for="(article, index) in section.articles" :key="article.id" class="article-card" :href="generateUrl(articleUrl, article.slug != null ? article.slug : article.id)">
                                        <div class="card-img">
                                            <img v-if="article.img" :src="article.img" alt="Нет изображения">
                                        </div>
                                        <div class="card-body">
                                            <div class="date">
                                                {{ article.updated_at ? formatDate(article.updated_at) : formatDate(article.created_at) }}
                                            </div>
                                            <div class="title">
                                                {{ article.title }}
                                            </div>
                                            <div class="description">
                                                {{ article.small_description }}
                                            </div>
                                            <div class="separator"/>
                                            <div class="section-btn">
                                                Читать далее
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-else class="tabs-content page-tabs__content">
                            <div class="courses-search-failed">
                                <div class="courses-search-failed__wrapper"><img class="courses-search-failed__img" :src="baseUrl + 'images/payment-failed.svg'" alt="">
                                    <p class="courses-search-failed__text">По этому запросу ничего не найдено</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ArticleEducationApp",
    props: [
        'initialSections',
        'initialTags',
        'initialSubjects',
        'initialDifficulties',
        'initialFormats',
        'sectionsUrl',
        'articleUrl',
        'isSection',
        'baseUrl',
    ],
    data() {
        return {
            sections: this.initialSections,
            tags: this.initialTags,
            subjects: this.initialSubjects,
            difficulties: this.initialDifficulties,
            formats: this.initialFormats,
            choiceTags: [],
            choiceSubjects: [],
            choiceDifficulties: [],
            choiceFormats: [],
            search: '',
            current_filter: 0,
        }
    },
    methods: {
        generateUrl(url, id) {
            return !url.includes('/') ? url + '/' + id : url + id;
        },
        toggle_filter(tab) {
            if (this.current_filter !== tab) {
                this.current_filter = tab;
            } else {
                this.current_filter = 0;
            }
        },
        clear(filter) {
            switch (filter) {
                case 'tags':
                    this.choiceTags = [];
                    break;
                case 'subjects':
                    this.choiceSubjects = [];
                    break;
                case 'difficulties':
                    this.choiceDifficulties = [];
                    break;
                case 'formats':
                    this.choiceFormats = [];
                    break;
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
            });
        },
        isArticleMatchFilters(article) {
            const tagMatch = this.isMatch(article.tags, this.choiceTags);
            const subjectMatch = this.isMatch(article.subjects, this.choiceSubjects);
            const difficultyMatch = this.isMatch(article.difficulties, this.choiceDifficulties);
            const formatMatch = this.choiceFormats.length === 0 || this.choiceFormats.includes(article.format.id);
            const searchMatch = this.search === '' ||
                article.title.toLowerCase().includes(this.search.toLowerCase()) ||
                article.small_description?.toLowerCase().includes(this.search.toLowerCase());

            return tagMatch && subjectMatch && difficultyMatch && formatMatch && searchMatch;
        },
        isMatch(articleAttributes, chosenAttributes) {
            if (chosenAttributes.length === 0) {
                return true;
            }
            return chosenAttributes.some(attr => articleAttributes.some(aAttr => aAttr.id === attr));
        },
    },
    computed: {
        filteredSections() {
            return this.sections
                .map(section => {
                    const filteredArticles = section.articles.filter(article => {
                        if (!article.format && this.choiceFormats.length > 0) {
                            return false;
                        }
                        return this.isArticleMatchFilters(article);
                    });

                    if (this.search !== ''
                        && filteredArticles.length === 0
                        && !section.title.toLowerCase().includes(this.search.toLowerCase())
                    ) {
                        return null;
                    }

                    return { ...section, articles: filteredArticles };
                })
                .filter(section => section !== null && section.articles.length > 0);
        },
    },
}
</script>

<style scoped>
.section {
    width: 100%;
}

.page-wrapper__content {
    width: 100%;
}

.tabs-content {
    padding-top: 5px;
}

.section__header {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
}

.all-sections {
    min-width: 262px;
    padding: 8px 16px 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    border: 1px solid #C9C7D1;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.all-sections:hover {
    color: #202023;
    background-color: #FFC759;
    border-color: #FFC759;
}

.article-cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
}

.article-cards__dont-section > :nth-child(n+4) {
    display: none;
}

@media (max-width: 1200px) {
    .article-cards {
        grid-template-columns: minmax(0, 1fr);
    }
    .article-cards__dont-section > :not(:first-child) {
        display: none;
    }
    .all-sections {
        min-width: unset;
    }
}

.article-card {
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0 #3F3F4514;
    cursor: pointer;
}

.card-img {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
}

.card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.card-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.date {
    font-size: 15px;
    color: #797780;
    margin-bottom: 8px;
}

.title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
}

.description {
    font-size: 17px;
    font-weight: 400;
    color: #797780;
    margin-bottom: 16px;
    height: 120px;
    overflow: hidden;

}

.separator {
    border: 1px solid #EDECF0;
    margin-top: auto;
}

.section-btn {
    padding: 16px 0 0 0;
    font-size: 15px;
    font-weight: 700;
}

.article-card:hover .section-btn {
    color: #FFC759;
}

.all-url {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}

</style>
